<template>
<div>
    <v-container>
        <v-row>
            <v-col cols="12">
                <s-toolbar :color="'#244093'" dark label="Generaciòn de  Asientos Contables" class="tran"></s-toolbar>
                <v-card>

                    <v-row justify="center" style="margin-top: 4px; margin-left: 10px; margin: auto">
                        <v-col cols="12" lg="3" md="3">
                            <s-select-definition :def="1135" v-model="objType" return-object label="Tipo"></s-select-definition>
                        </v-col>
                        <v-col cols="6" lg="4" md="4">
                            <s-select-definition :def="1131" v-model="objMounth" return-object label="Seleccione mes"></s-select-definition>
                        </v-col>
                        <v-col cols="6" lg="2" md="3">
                            <s-text label="Ingrese año" number v-model="txtYear"></s-text>
                        </v-col>

                    </v-row>
                    <v-row style="margin-left: auto; margin-left: 30px; margin-top: -20px">

                        <v-col cols="4" lg="2" md="2" class="mt-4 pl-0" v-if="this.radios == 1">
                            <v-btn width="100%" outlined rounded :color="'info'" small @click="run()"><i style="font-size: 20px" class="fas fa-spinner"></i> Generar</v-btn>
                        </v-col>

                        <v-col cols="4" lg="2" md="2" class="mt-4 pl-0" v-if="this.radios == 2">
                            <v-btn width="100%" outlined rounded :color="'error'" small @click="migrated()"><i style="font-size: 20px" class="fab fa-mixer"></i> Migrar data</v-btn>
                        </v-col>

                        <!-- <v-col cols="4" lg="2" md="2" class="mt-4 pl-0">
                            <v-spacer></v-spacer>
                            <v-btn width="100%" @click="show = !show" outlined rounded :color="'info'" small>
                                <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon><i style="font-size: 20px" class="fas fa-spinner"></i>Generar
                            </v-btn>
                        </v-col> -->

                        <v-col cols="4" lg="2" md="2" class="mt-4 pl-0">
                            <v-btn width="100%" outlined rounded :color="'warning'" small @click="runView()"><i style="font-size: 20px" class="fas fa-eye"></i> Visualizar</v-btn>
                        </v-col>
                        <v-col cols="4" lg="2" md="2" class="mt-4 pl-0">
                            <v-btn width="100%" outlined rounded :color="'success'" small @click="downloadReport()"><i style="font-size: 20px" class="fas fa-file-pdf"></i> Exportar</v-btn>
                        </v-col>

                    </v-row>

                    <!--  <v-row style="margin:auto">
                        <v-col cols="12">
                            <v-data-table :height="items.length > 0 ? '400px': 'auto'" :headers="headers" :items="items" dense :footer-props="{
                                    showCurrentPage: true,
                                    showFirstLastPage: true,
                                    itemsPerPageOptions: [5, 10, 50, 100],
                                }">
                            </v-data-table>
                        </v-col>
                    </v-row> -->

                </v-card>
            </v-col>
        </v-row>

        <v-card flat>
            <v-expand-transition>
                <div v-show="show">
                    <v-divider></v-divider>
                    <v-row justify="center" style="">

                        <v-col cols="12" lg="12" md="12" class="mt-3 ml-5" style="margin-bottom:-20px">

                            <v-radio-group v-model="radios">
                                <template v-slot:label>
                                    <div>Seleccione un metodo de generaciòn</div>
                                </template>
                                <v-radio value="1">
                                    <template v-slot:label>
                                        <div>Mov. Contable <strong class="error--text">PRUEBA</strong></div>
                                    </template>
                                </v-radio>
                                <v-radio value="2" style="margin-top: -10px">
                                    <template v-slot:label>
                                        <div>Mov. Contable <strong class="primary--text">PRODUCCIÒN</strong></div>
                                    </template>
                                </v-radio>
                            </v-radio-group>
                        </v-col>

                        <v-divider></v-divider>

                        <v-col cols="12" lg="12" md="12" class="mt-0 ml-5">
                            <v-row style="">
                                <v-col cols="6" lg="2" md="2">
                                    <v-radio-group v-model="bussines" hide-details style="margin-top: -10px;">
                                        <template v-slot:label>
                                            <div>Reiniciar Correlativo</div>
                                        </template>
                                        <v-radio value="IMPISA">
                                            <template v-slot:label>
                                                <div>IMPISA</div>
                                            </template>
                                        </v-radio>
                                        <v-radio value="IMPALG" style="margin-top: -10px">
                                            <template v-slot:label>
                                                <div>IMPALG</div>
                                            </template>
                                        </v-radio>
                                    </v-radio-group>
                                </v-col>
                                <v-col cols="6" lg="2" md="2">
                                    <v-btn style="text-transform: capitalize;" :color="'error'" small @click="restart()"><i style="font-size: 16px" class="fas fa-ban"></i> Reiniciar Co. </v-btn>
                                </v-col>

                            </v-row>

                        </v-col>

                    </v-row>

                </div>
            </v-expand-transition>

        </v-card>

        <v-card flat>
            <v-expand-transition>
                <div>
                    <v-divider></v-divider>
                    <v-row justify="center" style="">

                        <v-col cols="12" lg="12" md="12" class="mt-3 ml-5">
                            <v-data-table disable-sort :height="items.length > 0 ? '400px': 'auto'" :headers="headers" :items="items" dense :footer-props="{
                                    showCurrentPage: true,
                                    showFirstLastPage: true,
                                    itemsPerPageOptions: [5, 10, 50, 100],
                                }">
                            </v-data-table>
                        </v-col>

                    </v-row>

                </div>
            </v-expand-transition>

        </v-card>

        <v-dialog v-model="processing" v-if="processing" :persistent="messageProcessing.length == 0" width="400">
            <v-card color="primary" dark>
                <v-card-text v-if="messageProcessing.length == 0">
                    Esto puede tardar unos minutos, Por favor espere.
                    <v-progress-linear indeterminate color="white" class="mb-0 mt-3"></v-progress-linear>
                </v-card-text>
                <v-card-text v-else>
                    <div class="text--white pt-4">
                        Error al generar asientos contables <br />
                        {{ messageProcessing }}
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</div>
</template>

<script>
import _sAccounting from '@/services/Accounting/ConChargeMasive.js'
import _sQryConfigurationService from "@/services/QueryManager/QryConfigurationService.js";
export default {
    props: {

    },
    components: {},
    data() {
        return {
            show: true,
            processing: false,
            messageProcessing: "",

            items: [],
            headers: [{
                text: "",
                value: ""
            }],

            objMounth: null,
            objType: null,
            txtYear: this.$fun.getYear(),
            report: {},
            radios: null,
            bussines: null,
        };
    },

    methods: {
        run() {

            var obj = {
                Type: this.objType.DedValue,
                Mounth: this.objMounth.DedHelper,
                Year: this.txtYear,
                TypeGenerated: this.radios

            }

            if (obj.TypeGenerated == null) {
                this.$fun.alert("Seleccione un metodo de generaciòn", "warning");
                return
            }

            this.messageProcessing = ""
            this.$fun.alert("¿Seguro de generar ASIENTOS CONTABLES al Tipo ==> " + this.objType.DedDescription + " ? ", "question").then(x => {
                if (x.value) {
                    this.processing = true;
                    _sAccounting.generatedAccounting(obj, this.$fun.getUserID()).then(resp => {
                            if (resp.status == 200) {
                                this.processing = false;
                                this.$fun.alert("Asientos generados correctamente", "success")
                            }
                        },
                        (e) => {
                            this.messageProcessing = e.response.data.Message;
                            this.processing = true;
                        })

                }
            })

        },

        runView() {

            if (this.radios == null) {
                this.$fun.alert("Seleccione un metodo de generaciòn", "warning");
                return
            }

            this.messageProcessing = ""
            this.report.QryNameProcedure = "";
            this.report.QryParamsArray = "MES, ANIO, TYPEGENERATION"
            this.report.QryParamsArrayValue = this.objMounth.DedHelper + "," + this.txtYear + ', ' + this.radios //this.DateBegin + "," + this.DateEnd + "," + this.number;

            if (this.objType.DedValue == 1) { //IMPISA
                this.report.QryNameProcedure = "ConAccountingEntriesPrintIMPISA";

            }

            if (this.objType.DedValue == 2) { //IMPISA EXTERNA
                this.report.QryNameProcedure = "ConAccountingEntriesPrintExternaIMPISA";
            }

            if (this.objType.DedValue == 3) { //IMPALG
                this.report.QryNameProcedure = "ConAccountingEntriesPrintIMPALG";
            }

            if (this.objType.DedValue == 4) { //IMPALG EXTERNA
                this.report.QryNameProcedure = "ConAccountingEntriesPrintExternaIMPALG";
            }


            this.processing = true;
            _sQryConfigurationService
                .querygeneral(this.report, this.$fun.getUserID())
                .then(
                    (resp) => {
                        if (resp.status == 200) {
                            this.processing = false;
                            this.items = resp.data;

                            for (
                                var i = 0; i < Object.keys(this.items[0]).length; i++
                            ) {
                                this.headers.splice(i, i, {
                                    text: Object.keys(this.items[0])[i],
                                    value: Object.keys(this.items[0])[i],
                                    width: "120",
                                    sortable: false,
                                });
                            }

                        }
                    },
                    (e) => {
                        this.messageProcessing = e.response.data.Message;
                        this.processing = true;
                    }
                );
        },

        downloadReport() {

            if (this.radios == null) {
                this.$fun.alert("Seleccione un metodo de generaciòn", "warning");
                return
            }

            var name = "";
            this.messageProcessing = ""
            this.report.QryNameProcedure = "";
            this.report.QryParamsArray = "MES, ANIO, TYPEGENERATION"
            this.report.QryParamsArrayValue = this.objMounth.DedHelper + "," + this.txtYear + ', ' + this.radios //this.DateBegin + "," + this.DateEnd + "," + this.number;

            if (this.objType.DedValue == 1) { //IMPISA
                this.report.QryNameProcedure = "ConAccountingEntriesPrintIMPISA";
                name = this.radios == 1 ? "PRUEBA - MOV.CONTABLES IMPISA" : "MOV.CONTABLES IMPISA"

            }

            if (this.objType.DedValue == 2) { //IMPISA EXTERNA
                this.report.QryNameProcedure = "ConAccountingEntriesPrintExternaIMPISA";
                name = this.radios == 1 ? "PRUEBA - MOV.CONTABLES IMP EXT." : "MOV.CONTABLES IMPISA EXT."
            }

            if (this.objType.DedValue == 3) { //IMPALG
                this.report.QryNameProcedure = "ConAccountingEntriesPrintIMPALG";
                name = this.radios == 1 ? "PRUEBA - MOV.CONTABLES IMPALG" : "MOV.CONTABLES IMPALG"
            }

            if (this.objType.DedValue == 4) { //IMPALG EXTERNA
                this.report.QryNameProcedure = "ConAccountingEntriesPrintExternaIMPALG";
                name = this.radios == 1 ? "PRUEBA - MOV.CONTABLES IMPALG EXT." : "MOV.CONTABLES IMPALG EXT."
            }

            this.processing = true;
            _sQryConfigurationService
                .downloadexcel(this.report, this.$fun.getUserID())
                .then(
                    (r) => {
                        if (r.status == 200) {
                            this.processing = false;

                            this.$fun.downloadFile(
                                r.data,
                                this.$const.TypeFile.EXCEL,
                                name
                            );
                        }
                    },
                    (e) => {
                        this.messageProcessing = e.response.data.Message;
                        this.processing = true;
                    }
                );
        },

        migrated() {
            var obj = {
                Type: this.objType.DedValue,
                Mounth: this.objMounth.DedHelper,
                Year: this.txtYear
            }

            this.messageProcessing = ""
            this.$fun.alert("¿Esta intentando migrar data de Prueba a Producciòn, Seguro de Continuar ? Tipo: " + this.objType.DedDescription + " ? ", "question").then(x => {
                if (x.value) {
                    this.processing = true;
                    _sAccounting.migratedAccounting(obj, this.$fun.getUserID()).then(resp => {
                            if (resp.status == 200) {
                                this.processing = false;
                                this.$fun.alert("Data migrada correctamente", "success")
                            }
                        },
                        (e) => {
                            this.messageProcessing = e.response.data.Message;
                            this.processing = true;
                        })

                }
            })
        },

        restart() {

            if (this.bussines == null) {
                this.$fun.alert("Seleccione una empresa", "warning")
                return
            }
            this.$fun.alert("¿Seguro de reiniciar correlativo?", "question").then(val => {
                if (val.value) {
                    _sAccounting.restartCorrelative(this.bussines, this.$fun.getUserID()).then(resp => {
                        if (resp.status == 200) {
                            this.$fun.alert("Correlativo reiniciado correctamente", "success")
                        }
                    })
                }
            })
        }

    },
};
</script>

<style>
.tran {
    opacity: 0.7 !important;
}
</style>
